const getDataAllWallets = () => ([
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '1',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Margin',
            },
            wallets: [],
        },
        wallet_type: 'wallet',
        balance_diff_in_currency: 33.50,
        balance_diff_in_percentage: 2.70,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '2',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Spot',
            },
            wallets: [],
        },
        wallet_type: 'wallet',
        balance_diff_in_currency: -3.50,
        balance_diff_in_percentage: 2.70,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '3',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Futures 1',
            },
            wallets: [],
        },
        wallet_type: 'wallet',
        balance_diff_in_currency: 0,
        balance_diff_in_percentage: 0,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '4',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Futures 2',
            },
            wallets: [],
        },
        wallet_type: 'wallet',
        balance_diff_in_currency: 0,
        balance_diff_in_percentage: 0,
    },
]);
const getDataAllPortfolios = () => ([
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '1',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Margin',
            },
            wallets: [],
        },
        wallet_type: 'portfolio',
        balance_diff_in_currency: 33.50,
        balance_diff_in_percentage: 2.70,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '2',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Spot',
            },
            wallets: [],
        },
        wallet_type: 'portfolio',
        balance_diff_in_currency: -3.50,
        balance_diff_in_percentage: 2.70,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '3',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Futures 1',
            },
            wallets: [],
        },
        wallet_type: 'portfolio',
        balance_diff_in_currency: 0,
        balance_diff_in_percentage: 0,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '4',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Futures 2',
            },
            wallets: [],
        },
        wallet_type: 'portfolio',
        balance_diff_in_currency: 0,
        balance_diff_in_percentage: 0,
    },
]);
const getDataMixed = () => ([
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '1',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Margin',
            },
            wallets: [],
        },
        wallet_type: 'portfolio',
        balance_diff_in_currency: 33.50,
        balance_diff_in_percentage: 2.70,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '2',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Spot',
            },
            wallets: [],
        },
        wallet_type: 'portfolio',
        balance_diff_in_currency: -3.50,
        balance_diff_in_percentage: 2.70,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '3',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Futures 1',
            },
            wallets: [],
        },
        wallet_type: 'wallet',
        balance_diff_in_currency: 0,
        balance_diff_in_percentage: 0,
    },
    {
        id: '1',
        externalFinanceAccountId: '1',
        type: 'SPOT',
        assets: [],
        totalAssetsUsd: '1000',
        totalAssetsUsdt: '1000',
        exchange: {
            id: '4',
            type: 'BINANCE',
            userId: '1',
            properties: {
                apiKey: '',
                secretKey: '',
                connectionName: 'Binance Futures 2',
            },
            wallets: [],
        },
        wallet_type: 'wallet',
        balance_diff_in_currency: 0,
        balance_diff_in_percentage: 0,
    },
]);
export { getDataMixed, getDataAllPortfolios, getDataAllWallets, };
