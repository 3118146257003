import { jsx as _jsx } from "react/jsx-runtime";
import { ErrorComponent } from '@shared/ui/errors/Error';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import useEmailConfirm from './useEmailConfirm';
const ConfirmEmail = () => {
    const { isLoading, isError, } = useEmailConfirm();
    if (isLoading) {
        return (_jsx(LoadingIndicator, { isLoading: true, takeFullContainerSpace: true }));
    }
    if (isError) {
        return (_jsx(ErrorComponent, { variant: 'not_found' }));
    }
    return null;
};
export default ConfirmEmail;
